import React from "react"
import { graphql } from "gatsby"
import Link from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Link"

const GroupOverview = ({ data }) => {
  const groupsDE = data.groupsDE.edges
  const groupsEN = data.groupsEN.edges
  const groupsFR = data.groupsFR.edges
  return (
    <>
      <div className="mt-4 px-grid">
        <h1 className="mb-2 text-xl">Gruppen DE:</h1>
        <div className="flex flex-wrap">
          {groupsDE.map((group) => (
            <div className="mb-1 w-full">
              <Link className="hover:text-red-500" link={group.node.fullSlug}>
                {group.node.ID} {group.node.name}
              </Link>
            </div>
          ))}
        </div>
      </div>
      <div className="mt-4 px-grid">
        <h1 className="mb-2 text-xl">Gruppen EN:</h1>
        <div className="flex flex-wrap">
          {groupsEN.map((group) => (
            <div className="mb-1 w-full">
              <Link className="hover:text-red-500" link={group.node.fullSlug}>
                {group.node.ID} {group.node.name}
              </Link>
            </div>
          ))}
        </div>
      </div>
      <div className="mt-4 px-grid">
        <h1 className="mb-2 text-xl">Gruppen FR:</h1>
        <div className="flex flex-wrap">
          {groupsFR.map((group) => (
            <div className="mb-1 w-full">
              <Link className="hover:text-red-500" link={group.node.fullSlug}>
                {group.node.ID} {group.node.name}
              </Link>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

export const data = graphql`
  {
    groupsDE: allContentServProductGroup(filter: { locale: { eq: "de" } }) {
      edges {
        node {
          ID
          name
          locale
          fullSlug
        }
      }
    }
    groupsEN: allContentServProductGroup(filter: { locale: { eq: "en" } }) {
      edges {
        node {
          ID
          name
          locale
          fullSlug
        }
      }
    }
    groupsFR: allContentServProductGroup(filter: { locale: { eq: "fr" } }) {
      edges {
        node {
          ID
          name
          locale
          fullSlug
        }
      }
    }
  }
`

export default GroupOverview
